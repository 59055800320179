/* eslint-disable prettier/prettier */
module.exports = {
  'tabBar.activities': 'Activités',
  'tabBar.menu': 'Menu',
  'global.number': 'Nombre',
  'global.email': 'Email',
  'global.search': 'Search',
  'global.filter': 'Filtrer',
  'global.form.name': 'Nom',
  'global.form.yes': 'Oui',
  'global.form.no': 'Non',
  'global.form.workflowPlaceholder': 'Selectionnez un Workflow',
  'global.form.statePlaceholder': 'Selectionnez un état',
  'global.form.modulePlaceholder': 'Selectionnez un module',
  'global.unspecified': 'Non spécifié',
  'global.customFields': 'Champs personnalisable',
  'global.content': 'Content',
  'global.form.errors.field.required': 'Champ requis',
  'global.form.errors.number': 'Champ est un nombre',
  'global.form.errors.content.required': 'Le contenu est requis',
  'global.form.errors.email.required': 'Le mail  est requis',
  'global.form.errors.email.email': 'Le mail est invalide',
  'global.form.errors.url': 'URL est invalide',
  'global.form.errors.password.required': 'Le mot de passe est requis',
  'global.form.errors.firstName.required': 'Le prénom est requis',
  'global.form.errors.lastName.required': 'Le nom est requis',
  'global.form.errors.firstName.invalid': 'Prénom invalide',
  'global.form.errors.lastName.invalid': 'Nom invalide',
  'global.form.errors.state.required': 'Choisissez un état',
  'global.form.errors.workflow.required': 'Choisissez un workflow',
  'global.form.errors.module.required': 'Choisissez un module',
  'global.save': 'Sauvegarder',
  'global.close': 'Fermer',
  'global.edit': 'Editer',
  'global.view': 'Voir',
  'global.remove': 'Supprimer',
  'global.finish': 'Finir',
  'global.password': 'Mot de passe',
  'global.or': 'Ou',
  'global.next': 'Suivant',
  'global.confirmAction': 'Confirmer',
  'global.cancel': 'Annuler',
  'global.upgrade': 'Upgrader',
  'global.subscribe': 'Souscrire',
  'global.form.line1': 'Ligne 1',
  'global.form.line2': 'Ligne 2',
  'global.form.country': 'Pays',
  'global.form.city': 'Ville',
  'global.form.zip': 'Code postal',
  'global.form.firstName': 'Prénom',
  'global.form.lastName': 'Nom',
  'global.form.phone': 'Téléphone',
  'global.form.company': 'Compagnie',
  'global.form.email': 'Email',
  'global.openMenu': 'Ouvrir le menu',
  'global.prediwell': 'Prediwell',
  'global.openMyAccount': 'Gérer votre compte',
  'global.internalError': 'Oops, Erreur. Veuillez réessayer',

  'module.signin.forgotPassword': 'Mot de passe oublié?',
  'module.signin.startFreeTrial': 'Commencer l\'essai gratuit',

  'module.signup.tryPrediwellForFree': 'Essayer Prediwell gratuitement',
  'module.signup.immediateAccessNoCC':
    'Accès immédiat. Aucune carte bleue nécessaire',
  'module.signup.privacyPolicy':
    'En vous connectant, vous accepter nos conditions générales {termsOfService} et {privacyPolicy}',
  'module.signup.alreadyRegistered': 'Vous avez déjà un compte? {loginLink}',
  'global.logIn': 'Se connecter',
  'global.register': 'Créer un compte',
  'module.signup.process.loading': 'Nous créons votre compte',
  'module.signup.process.checkEmail':
    'Vérifiez vos email pour valider votre accès à Prediwell!',
  'module.signup.process.complete':
    'Votre compte a été créé!',

  'module.menu.personal': 'Personnel',
  'module.menu.account': 'Mon compte',
  'module.menu.languages': 'Langages',
  'module.menu.logout': 'Se deconnecter',
  'module.menu.personal.spaces': 'Espaces de travail',
  'module.languages.french': 'Français',
  'module.languages.english': 'Anglais',
  'module.languages.spanish': 'Espagnol',

  'module.initialSetup.process.loading': 'Nous préparons votre espace de travail ...',
  'module.initialSetup.process.complete':
    'Votre espace de travail est prêt. Profitez de Prediwell!',

  'module.search.heading': 'Recherche Prediwell',
  'module.search.input.placeholder': 'Recherche Prediwell',
  'module.search.description': 'Trouvez vos contacts et activités',

  'module.activities.list.emptyList': "Vous n'avez aucun contact ou activité.",
  'module.activities.createActivity': 'Créer une activité',
  'module.activities.removeActivity': 'Supprimer définitivement l\'acivité',
  'module.activities.wizard.workflowConfig.title':
    'configurer le workflow de l\'activité',
  'module.activities.wizard.contactInformation.title':
    'Saisir les informations du contact',
  'module.activities.wizard.customFields.title': 'Configurez vos champs personalisés.',
  'module.activities.wizard.firstName': 'Prénom',
  'module.activities.wizard.lastName': 'Nom',
  'module.activities.wizard.phone': 'Téléphone',
  'module.activities.wizard.company': 'Compagnie',
  'module.activities.wizard.web': 'Web',
  'module.activities.wizard.position': 'Position',

  'module.activities.tabs.main': 'Details',
  'module.activities.tabs.notes': 'Notes',
  'module.activities.tabs.customFields': 'Champs personalisés',
  'module.activities.details.createNote': 'Créer une note',
  'module.activities.details.customFields.actions.viewDetails':
    'Voir les details du champs personalisé',
  'module.activities.details.customFields.actions.editCustomFieldValue':
    'Editer la valeur du champs personalisé',
  'module.activities.details.customFields.editValue': 'Editer la valeur "{name}"',
  'module.activities.details.notes.emptyList':
    'Cette activité ne comporte aucune note',

  'module.activities.wizard.title': 'Créer une activité',
  'module.activities.wizard.similarActivityWarning':
    'Cette activité est similaire à une autre existante. Voulez vous continuer?',

  'module.activities.wizard.process.saving': 'Sauvegarde en cours ...',
  'module.activities.wizard.process.complete':
    'L\'activité a été créée!',

  'module.activities.wizard.form.searchContact': 'Recherche de contact',
  'module.activities.wizard.form.noContact': 'Aucun contact trouvé',

  'module.customFields.createCustomField': 'Créer un champ personalisé',
  'module.customFields.emptyList': "Vous n'avez pas encore de champ personalisé.",
  'module.customFields.cta.createCustomField': 'Créer un champ personalisé',
  'module.customFields.form.validationRule': 'Règle de validation',
  'module.customFields.form.validationRule.number': 'Nombre',
  'module.customFields.form.validationRule.URL': 'URL',
  'module.customFields.form.validationRule.date': 'Date',
  'module.customFields.form.validationRule.unspecified': 'Non Specifié',

  'module.notImplemented.title':
    'Cette fonctionalité n\'est pas encore implémentée sur mobile. ',
  'module.notImplemented.description':
    'Veuillez vous connecter à l\'application web pour une expérience complète Prediwell.',

  'module.initialSetup.title': 'Aidez-nous à enrichir votre expérience Prediwell',
  'module.initialSetup.subtitle': 'Encore quelque petites informations',
  'module.initialSetup.form.firstName': 'Votre prénom (Optional)',
  'module.initialSetup.form.lastName': 'Votre nom (Optional)',
  'module.initialSetup.form.workingField': 'Votre secteur d\'activité?',
  'module.initialSetup.form.workingField.required': 'Choisissez un secteur d\'activité',
  'module.initialSetup.form.IT': 'IT',
  'module.initialSetup.form.sales': 'Vente ou Commercial',
  'module.initialSetup.form.recruitment': 'Recrutement',
  'module.initialSetup.form.others': 'Autres',
  'module.initialSetup.sales.coldProspect': 'Prospect froid',
  'module.initialSetup.sales.qualifiedProspect': 'Prospect qualifié', 'module.initialSetup.sales.lead': 'Lead',
  'module.initialSetup.sales.client': 'Client',
  'module.initialSetup.sales.disqualifiedProspect': 'Prospect disqualifié',
  'module.initialSetup.staff.coldCandidate': 'Candidat(e) à qualifier',
  'module.initialSetup.staff.qualifiedCandidate': 'Candidat(e) qualifié(e)',
  'module.initialSetup.staff.disqualifiedCandidate':
    'Candidat(e) disqualifié(e)',
  'module.initialSetup.staff.probationPeriod': "Période d'essai",
  'module.initialSetup.staff.employmentConfirmed': 'Emploi confirmé',
  'module.initialSetup.it.availableConsultant': 'Consultant(e) disponible',
  'module.initialSetup.it.clientInterview': 'Entretien client',
  'module.initialSetup.it.resignedConsultant': "Annulation de l'entretien",
  'module.initialSetup.it.positionedConsultant': 'Entretien prévu',
  'module.initialSetup.others.lead': 'Lead',
  'module.initialSetup.others.deal': 'Deal',
  'module.initialSetup.others.proposition': 'Proposition',
  'module.initialSetup.actions.cancel': 'Annuler',
  'module.initialSetup.actions.conversation': 'Conversation',
  'module.initialSetup.actions.quoteAccepted': 'Devis accepté',
  'module.initialSetup.actions.identifiedOpportunity': 'Opportunité identifiée',
  'module.initialSetup.actions.quotaAccepted': 'Devis accepté',
  'module.initialSetup.actions.dismiss': 'Refus',
  'module.initialSetup.actions.noInterest': 'Sans interêt',
  'module.initialSetup.actions.cancelOpportunity': 'Opportunité annulée',
  'module.initialSetup.actions.fit': 'Convient',
  'module.initialSetup.actions.unfit': 'Ne convient pas',
  'module.initialSetup.actions.validateByClient': 'Validation par le client',
  'module.initialSetup.actions.confirm': 'Confirmé',
  'module.initialSetup.actions.endContract': 'Fin de contrat',
  'module.initialSetup.actions.disqualifiedByClient': 'Disqualifié par le client',
  'module.initialSetup.actions.position': 'Position',
  'module.initialSetup.actions.consultantQuits': 'Départ du consultant',
  'module.initialSetup.actions.validate': 'Valider',

  'module.account.headings.myAccount': 'Mon compte',
  'module.account.headings.subscriptionInformation': 'Information de l\'abonnement',
  'module.account.headings.billingContact': 'Contact de facturation',
  'module.account.headings.paymentMethod': 'Méthode de paiement',
  'module.account.noAddress': "Vous n'avez pas saisi d'adresse",
  'module.account.cancelSubscription': 'Annuler mon abonnement',
  'module.account.actions.editPaymentMethod': 'Modifier la méthode de paiement',
  'module.account.actions.editAddress': 'Modifier l\'adresse de paiement',
  'module.account.actions.editBasicInfo': 'Modifier les informations basiques',
  'module.account.actions.removePaymentMethod': 'Supprimer la méthode de paiement',
  'module.account.heading.editAddress': 'Modifier l\'adresse de paiement',
  'module.account.heading.editBasicInformation': 'Modifier',
  'module.account.heading.editPaymentMethod': 'Modifier la méthode de paiement',
  'module.account.form.cardholderName': 'Nom sur la carte',
  'module.account.form.creditCard': 'Carte de crédit',
  'module.account.form.creditCard.error': 'La carte de crédit est requise',
  'module.account.form.cardholderName.error': 'Le nom sur la carte est requis',
  'module.account.form.cancellationReason': 'Motif d\'annulation',
  'module.account.cancellationResult.success': 'Votre abonnement a été annulé.',
  'module.account.removePaymentMethod.confirm': 'Etes vous sur de vouloir annuler la méthode de paiement?',

  'module.checkout': 'Checkout',
  'module.checkout.form.plan.required': 'Veuillez choisir un plan',
  'module.checkout.perUserMonthly': 'Par utilisateur par mois',
  'module.checkout.unlimitedActivities': 'activités illimiteés',
  'module.checkout.unlimitedWorkflows': 'workflows illimités',
  'module.checkout.unlimitedSpaces': 'Espaces de travail illimités',
  'module.checkout.activitiesQuota': 'Jusqu\'à {quota} activités',
  'module.checkout.spacesQuota': 'Jusqu\'à {quota} espaces de travail',
  'module.checkout.workflowsQuota': 'Jusqu\'à {quota} workflows',

  'module.checkout.description.insights': 'Accédez à vos informations sur les données',
  'module.checkout.description.teamBoard':
    'Améliorez la collaboration de vos utilisateurs avec des tableaux de bord interactifs',
  'module.checkout.description.taskManagement':
    'Gérez vos tâches avec le gestionnaire de tâches',
  'module.checkout.description.dataExport':
    'Exportez vos activités dans des fichiers CSV',
  'module.checkout.description.dataImport':
    'Importez vos données existantes avec notre outil d\'import',
  'module.checkout.description.filesStorage':
    'Téléchargez des pièces jointes dans vos activités',
  'module.checkout.description.mailing':
    'Intégrez votre boite mail dans Prediwell',
  'module.checkout.planRequired': 'Veuillez choisir un plan',
  'module.checkout.cardDeclined': 'Votre carte a été refusée',
  'module.checkout.paymentMethodRequired': 'Veuillez choisir une methode de paiement',
  'module.checkout.addressRequired': 'Veuillez saisir votre adresse',
  'module.account.paymentProcessing': 'Traitement de votre paiement en cours',
  'module.checkout.basicInfoRequired': 'Veuillez saisir votre nom complet',
  'module.checkout.paymentProcessing.finished':
    'Paiement effectué.',
  'module.checkout.paymentProcessing.attemptsExhausted':
    'Votre paiement prend plus de temps qu\'espéré. Veuillez raffraichir la page',
  'module.checkout.paymentProcessing.started':
    'Nous procédons au paiement. Veuillez patienter',

  'module.account.expires': 'Expire le',
  'module.account.expiresAt': 'Expire le {date}',
  'module.account.subscriptionExpired': 'Expiré le {date}',
  'module.account.subscriptionCancelled': 'Annulé le {date}',
  'module.account.noPaymentMethod': "Vous n'avez pas encore configuré une méthode de paiement",

  'module.userSpaces.heading1': 'Choisir un espace de travail',

  'module.userVerification.loading': 'Veuillez patienter quelques secondes...',
  'module.userVerification.complete': 'Vérification en cours. vous serez redirigé vers la page de login',

  'module.forgotPassword.alreadyRegistered': 'Essayer un autre compte? {signin}',
  'module.forgotPassword.createAccount': 'Créer un compte? {signup}',
  'module.forgotPassword.submit': 'Envoyer l\'email',
  'module.forgotPassword.title': 'Prediwell',
  'module.forgotPassword.description': 'Veuillez saisir votre addresse email',
  'module.forgotPassword.loading': 'Veuillez patienter quelques secondes ...',
  'module.forgotPassword.complete': 'Nous vous avons envoyé un email. Veuillez consulter votre boite mail',

  'module.subscription.restrictedProduct': 'Fonctionalité indisponible',
  'module.subscription.restrictedProduct.message': 'Cette fonctionalité est indisponible dans votre abonnement. Veuillez changer d\'abonnement pour l\'activer.',
  'module.subscription.subscriptionRequired': 'Un abonnement est nécessaire pour utiliser Prediwell. Essayer gratuitement!',
  'module.subscription.subscriptionCanceled': 'Votre abonnement a été annulé le {cancellationDate}.',
  'module.subscription.subscriptionFailed': 'Votre dernier paiement a échoué. Veuiller consulter votre compte Prediwell pour résoudre le problème',
  'module.subscription.subscriptionExpired': 'Votre abonnement a expiré. Veuillez le renouveller pour accéder à Prediwell.',
  'module.subscription.inaccessibleSpace': 'Cet espace de travail est inaccessible. Veuillez contacter le propriétaire pour résoudre le problème',
  'module.subscription.incomplete': "Le dernier paiement n'a pas abouti. Veuillez résoudre le problème de methode de paiement pour corriger le problème",

  'module.fixPayment.chooseAction': 'Choisir une action',
  'module.fixPayment.fixIssue': 'Résoudre le problement de méthode de paiement',
  'module.fixPayment.completePayment': 'Continuer mon dernier paiement',
};
