/* eslint-disable prettier/prettier */
module.exports = {
  'tabBar.activities': 'Actividades',
  'tabBar.menu': 'Menú',
  'global.number': 'Número',
  'global.email': 'Email',
  'global.search': 'Buscar',
  'global.filter': 'Filtrar',
  'global.form.name': 'Nombre',
  'global.form.yes': 'Sí',
  'global.form.no': 'No',
  'global.form.workflowPlaceholder': 'Seleccionar un Workflow',
  'global.form.statePlaceholder': 'Seleccionar un estado',
  'global.form.modulePlaceholder': 'Seleccionar un estado',
  'global.unspecified': 'No especificado',
  'global.customFields': 'Campos personalizados',
  'global.content': 'Contenido',
  'global.form.errors.field.required': 'El campo es necesario',
  'global.form.errors.number': 'El campo es número',
  'global.form.errors.content.required': 'Un contenido es necesario',
  'global.form.errors.email.required': 'Una dirección de e-mail es necesaria',
  'global.form.errors.email.email': 'El e-mail es inválido',
  'global.form.errors.url': 'URL es inválido',
  'global.form.errors.password.required': 'Una contraseña es necesaria',
  'global.form.errors.firstName.required': 'Un nombre es necesario',
  'global.form.errors.lastName.required': 'Un apellido es necesario',
  'global.form.errors.firstName.invalid': 'El nombre es inválido',
  'global.form.errors.lastName.invalid': 'El apellido es inválido',
  'global.form.errors.state.required': 'Elige un estado',
  'global.form.errors.workflow.required': 'Elige un Workflow',
  'global.form.errors.module.required': 'Elige un módulo',
  'global.save': 'Guardar',
  'global.close': 'Cerrar',
  'global.edit': 'Modificar',
  'global.view': 'Ver',
  'global.remove': 'Suprimir',
  'global.finish': 'Terminar',
  'global.password': 'Contraseña',
  'global.or': 'O',
  'global.next': 'Siguiente',
  'global.confirmAction': 'Confirmar la acción',
  'global.cancel': 'Cancelar',
  'global.upgrade': 'Actualizar',
  'global.subscribe': 'Suscribir',
  'global.form.line1': 'Línea 1',
  'global.form.line2': 'Línea 2',
  'global.form.country': 'País',
  'global.form.city': 'Ciudad',
  'global.form.zip': 'Zip',
  'global.form.firstName': 'Nombre',
  'global.form.lastName': 'Apellido',
  'global.form.phone': 'Número de teléfono',
  'global.form.company': 'Empresa',
  'global.form.email': 'Email',
  'global.openMenu': 'Abrir el menú',
  'global.prediwell': 'Prediwell',
  'global.openMyAccount': 'Ver las configuraciones de mi cuenta',
  'global.internalError': 'Ups, algo salió mal. Intenta nuevamente',

  'module.signin.forgotPassword': '¿Has olvido tu contraseña?',
  'module.signin.startFreeTrial': 'Iniciar una prueba gratis',

  'module.signup.tryPrediwellForFree': 'Intenta Prediwell de manera gratis',
  'module.signup.immediateAccessNoCC':
    'Acceso inmediato. No necesita tarjeta de crédito.',
  'module.signup.privacyPolicy':
    'Cuando te suscribes, aceptas nuestros {termsOfService} {privacyPolicy}',
  'module.signup.alreadyRegistered': '¿Ya tienes una cuenta? {loginLink}',
  'global.logIn': 'Iniciar sesión',
  'global.register': 'Registrarse',
  'module.signup.process.loading': 'Estamos creando tu cuenta',
  'module.signup.process.checkEmail':
    'Verifica en tu buzón para confirmar y acceder a Prediwell!',
  'module.signup.process.complete':
    '¡Has creado tu cuenta con exito!',

  'module.menu.personal': 'Personal',
  'module.menu.account': 'Configuraciones de la cuenta',
  'module.menu.languages': 'Idiomas',
  'module.menu.logout': 'Desconectarse',
  'module.menu.personal.spaces': 'Tus Espacios de tabajo',
  'module.languages.french': 'Francés',
  'module.languages.english': 'Inglés',
  'module.languages.spanish': 'Español',

  'module.initialSetup.process.loading': 'Estamos preparando tu espacio de trabajo...',
  'module.initialSetup.process.complete':
    'Tu espacio de trabajo es creado. ¡Aprovecha de Prediwell!',

  'module.search.heading': 'Buscar Prediwell',
  'module.search.input.placeholder': 'Buscar Prediwell',
  'module.search.description': 'Encuenta tus contactos y actividades',

  'module.activities.list.emptyList': "Aún no tienes ningún actividad",
  'module.activities.createActivity': 'Crear una actividad',
  'module.activities.removeActivity': 'Suprimir la atividad de manera permanente',
  'module.activities.wizard.workflowConfig.title':
    'configurar un Workflow para tu actividad',
  'module.activities.wizard.contactInformation.title':
    'Entrar las informaciones de tu contacto',
  'module.activities.wizard.customFields.title': 'Configurar tus campos personalizados',
  'module.activities.wizard.firstName': 'Nombre',
  'module.activities.wizard.lastName': 'Apellido',
  'module.activities.wizard.phone': 'Número de teléfono',
  'module.activities.wizard.company': 'Empresa',
  'module.activities.wizard.web': 'Sitio Web',
  'module.activities.wizard.position': 'Puesto',

  'module.activities.tabs.main': 'Detalles',
  'module.activities.tabs.notes': 'Notas',
  'module.activities.tabs.customFields': 'Campos personalizados',
  'module.activities.details.createNote': 'Crear una nota',
  'module.activities.details.customFields.actions.viewDetails':
    'Ver las detalles de los campos personalizados',
  'module.activities.details.customFields.actions.editCustomFieldValue':
    'Modificar el valor del campo personalizado',
  'module.activities.details.customFields.editValue': 'Modificar el valor "{name}"',
  'module.activities.details.notes.emptyList':
    'Esta actividad aún no tiene nota',

  'module.activities.wizard.title': 'Crear una actividad',
  'module.activities.wizard.similarActivityWarning':
    'Ya hay una acitivdad similar. ¿Estás seguro que quieres continuar?',

  'module.activities.wizard.process.saving': 'Estamos registrando...',
  'module.activities.wizard.process.complete':
    '¡Tu actividad ha sido creada con exito!',

  'module.activities.wizard.form.searchContact': 'Buscar un contacto',
  'module.activities.wizard.form.noContact': 'No hemos encontrado ningún contacto',

  'module.customFields.createCustomField': 'Crear un campo personalizado',
  'module.customFields.emptyList': 'Aún no tienes ningún campo personalizado',
  'module.customFields.cta.createCustomField': 'Crear un campo personalizado',
  'module.customFields.form.validationRule': 'Regla de validación',
  'module.customFields.form.validationRule.number': 'Número',
  'module.customFields.form.validationRule.URL': 'URL',
  'module.customFields.form.validationRule.date': 'Fecha',
  'module.customFields.form.validationRule.unspecified': 'No especificado',

  'module.notImplemented.title':
    'Esta funcionalidad aún no está implementada en la versión móvil.',
  'module.notImplemented.description':
    'Acceda a nuestra aplicación web en la versión escritorio para aprovechar de la experiencia completa de Prediwell',

  'module.initialSetup.title': 'Nos ayuda a enriquecer su experiencia previa al bienestar',
  'module.initialSetup.subtitle': 'Necesitamos detalles adicionales',
  'module.initialSetup.form.firstName': 'Tu nombre (Opcional)',
  'module.initialSetup.form.lastName': 'Tu apellido (Opcional)',
  'module.initialSetup.form.workingField': '¿Qué es tu campo de trabajo?',
  'module.initialSetup.form.workingField.required': 'Elige un campo de trabajo',
  'module.initialSetup.form.IT': 'IT',
  'module.initialSetup.form.sales': 'Sales',
  'module.initialSetup.form.recruitment': 'Reclutamiento',
  'module.initialSetup.form.others': 'Otras',
  'module.initialSetup.sales.coldProspect': 'Prospecto en frío',
  'module.initialSetup.sales.qualifiedProspect': 'Prospecto calificado',
  'module.initialSetup.sales.lead': 'Cliente potencial',
  'module.initialSetup.sales.client': 'Cliente',
  'module.initialSetup.sales.disqualifiedProspect': 'Prospecto descalificado',
  'module.initialSetup.staff.coldCandidate': 'Candidato',
  'module.initialSetup.staff.qualifiedCandidate': 'Candidato calificado',
  'module.initialSetup.staff.disqualifiedCandidate': 'Candidato descalificado',
  'module.initialSetup.staff.probationPeriod': 'Periodo de Prueba',
  'module.initialSetup.staff.employmentConfirmed': 'Servicio Realizado',
  'module.initialSetup.it.availableConsultant': 'Consultor(a) disponible',
  'module.initialSetup.it.clientInterview': 'Entrevista con cliente',
  'module.initialSetup.it.resignedConsultant': 'Entrevista denegada',
  'module.initialSetup.it.positionedConsultant': 'Entrevista asignada',
  'module.initialSetup.others.lead': 'Oportunidad',
  'module.initialSetup.others.deal': 'Acuerdo',
  'module.initialSetup.others.proposition': 'Propuesta',
  'module.initialSetup.actions.cancel': 'Cancelado',
  'module.initialSetup.actions.conversation': 'Negociación',
  'module.initialSetup.actions.quoteAccepted': 'Cotización aceptada',
  'module.initialSetup.actions.identifiedOpportunity': 'Oportunidad identificada',
  'module.initialSetup.actions.quotaAccepted': 'Cotización Aceptada',
  'module.initialSetup.actions.dismiss': 'Descartar',
  'module.initialSetup.actions.noInterest': 'No hay interés',
  'module.initialSetup.actions.cancelOpportunity': 'Oportunidad cancelada',
  'module.initialSetup.actions.fit': 'Ajuste',
  'module.initialSetup.actions.unfit': 'No apto',
  'module.initialSetup.actions.validateByClient': 'Validado por el cliente',
  'module.initialSetup.actions.confirm': 'Confirmar',
  'module.initialSetup.actions.endContract': 'Finalizar contrato',
  'module.initialSetup.actions.disqualifiedByClient': 'Descalificado por el cliente',
  'module.initialSetup.actions.position': 'Posición',
  'module.initialSetup.actions.consultantQuits': 'Renuncia del consultor',
  'module.initialSetup.actions.validate': 'Validado',

  'module.account.headings.myAccount': 'Mi cuenta',
  'module.account.headings.subscriptionInformation': 'Información de suscripción',
  'module.account.headings.billingContact': 'Contacto de facturación',
  'module.account.headings.paymentMethod': 'Método de pago',
  'module.account.noAddress': "No has introducido una dirección",
  'module.account.cancelSubscription': 'Cancelar mi suscripción',
  'module.account.actions.editPaymentMethod': 'Modificar el método de pago',
  'module.account.actions.editAddress': 'Modificar la dirección de pago',
  'module.account.actions.editBasicInfo': 'Modificar las informaciones básicas',
  'module.account.actions.removePaymentMethod': 'Suprimir el método de pago',
  'module.account.heading.editAddress': 'Modificar la dirección de pago',
  'module.account.heading.editBasicInformation': 'Modificar las informaciones básicas',
  'module.account.heading.editPaymentMethod': 'Modificar el método de pago',
  'module.account.form.cardholderName': 'Nombre del titular de la tarjeta',
  'module.account.form.creditCard': 'Tarjeta de crédito',
  'module.account.form.creditCard.error': 'Una tarjeta de crédito es necesaria',
  'module.account.form.cardholderName.error': 'El nombre del titular de la tarjeta es necesario',
  'module.account.form.cancellationReason': 'Motivo de la anulación',
  'module.account.cancellationResult.success': 'Tu suscripción ha sido cancelada.',
  'module.account.removePaymentMethod.confirm': '¿Estás seguro que quieres suprmir tu método de pago?',

  'module.checkout': 'Checkout',
  'module.checkout.form.plan.required': 'Elige un plan',
  'module.checkout.perUserMonthly': 'Por usuario de manera mensual',
  'module.checkout.unlimitedActivities': 'Actividades ilimitadas',
  'module.checkout.unlimitedWorkflows': 'Workflows ilimitados',
  'module.checkout.unlimitedSpaces': 'Espacios de trabajos ilimitados',
  'module.checkout.activitiesQuota': 'Hasta{quota} actividades',
  'module.checkout.spacesQuota': 'Hasta {quota} espacios',
  'module.checkout.workflowsQuota': 'Hasta {quota} workflows',

  'module.checkout.description.insights': 'Accede a tus datos insights',
  'module.checkout.description.teamBoard':
    'Mejora la colaboración de tus usuarios con los tableros de equipo interactivos Team Board',
  'module.checkout.description.taskManagement':
    'Gestiona tus tareas con el Administrador de tareas',
  'module.checkout.description.dataExport':
    'Export your activities data into CSV files',
  'module.checkout.description.dataImport':
    'Importa tus datos de negocios existentes con nuestro herramienta de importación',
  'module.checkout.description.filesStorage':
    'Carga archivos adjuntos a tu actividad',
  'module.checkout.description.mailing':
    'Integrar tu propio buzón de correo electrónico en Prediwell',
  'module.checkout.planRequired': 'Elige un plan',
  'module.checkout.cardDeclined': 'Tu tarjeta ha sido rechazada',
  'module.checkout.paymentMethodRequired': 'Elige un método de pago',
  'module.checkout.addressRequired': 'Introduce tu dirección',
  'module.account.paymentProcessing': 'Estamos procesando tu pago',
  'module.checkout.basicInfoRequired': 'Introduce tu nombre completo',
  'module.checkout.paymentProcessing.finished':
    'La tramitación del pago es finalizada',
  'module.checkout.paymentProcessing.attemptsExhausted':
    'tu pago ha tardado más de lo esperado, actualize la página',
  'module.checkout.paymentProcessing.started':
    'Estamos procesando tu pago, espera',

  'module.account.expires': 'Expira el',
  'module.account.expiresAt': 'Expira el {date}',
  'module.account.subscriptionExpired': 'Expira el {date}',
  'module.account.subscriptionCancelled': 'Cancelado en el {date}',
  'module.account.noPaymentMethod': "Aún no has introducido un método de pago",

  'module.userSpaces.heading1': 'Elige tu espacio de trabajo',

  'module.userVerification.loading': 'Espera algunos segundos...',
  'module.userVerification.complete': 'Tu cuenta es verificada. Vas\a ser redirigido en la página de conexión',

  'module.forgotPassword.alreadyRegistered': '¿Intentar una otra cuenta? {signin}',
  'module.forgotPassword.createAccount': 'Crear una cuenta? {signup}',
  'module.forgotPassword.submit': 'Reiniciar la contraseña',
  'module.forgotPassword.title': 'Prediwell',
  'module.forgotPassword.description': 'Indicanos tu mail y te ayudaremos a cambiar tu contraseña.',
  'module.forgotPassword.loading': 'Espera algunos segundos...',
  'module.forgotPassword.complete': 'Te hemos enviado un mail. Verifica tu buzón de correo electrónico.',

  'module.subscription.restrictedProduct': 'Funcionalidad inválida',
  'module.subscription.restrictedProduct.message': 'Esta funcionalidad no es disponible en tu plan. Tienes que cambiar tu plan para tenerla',
  'module.subscription.subscriptionRequired': 'Necesitas registrarte para utilizar Prediwell. ¡Intenta de manera gratis!',
  'module.subscription.subscriptionCanceled': 'Tu suscripción fue cancelada en el {cancellationDate}.',
  'module.subscription.subscriptionFailed': 'Tu último pago es invalido. Visita tu cuenta para resolver el problema',
  'module.subscription.subscriptionExpired': 'Tu suscripción expiró. Renueva tu plan para acceder a tus datos',
  'module.subscription.inaccessibleSpace': 'Este expacio de trabajo es inaccessible. Contacta el propietario para resolver el problema',
  'module.subscription.incomplete': "Aún no has completado tu último pago. Resuelve tu problema de método de pago",

  'module.fixPayment.chooseAction': 'Elige una acción',
  'module.fixPayment.fixIssue': 'Resolver un problema de pago',
  'module.fixPayment.completePayment': 'Completar mi último pago',
};
