/* eslint-disable prettier/prettier */
module.exports = {
  'tabBar.activities': 'Activities',
  'tabBar.menu': 'Menu',
  'global.number': 'Number',
  'global.email': 'Email',
  'global.search': 'Search',
  'global.filter': 'Filter',
  'global.form.name': 'Name',
  'global.form.yes': 'Yes',
  'global.form.no': 'No',
  'global.form.workflowPlaceholder': 'Select a Workflow',
  'global.form.statePlaceholder': 'Select state',
  'global.form.modulePlaceholder': 'Select state',
  'global.unspecified': 'Unspecified',
  'global.customFields': 'Custom Fields',
  'global.content': 'Content',
  'global.form.errors.field.required': 'Field required',
  'global.form.errors.number': 'Field is number',
  'global.form.errors.content.required': 'Content is required',
  'global.form.errors.email.required': 'Email is required',
  'global.form.errors.email.email': 'Email is invalid',
  'global.form.errors.url': 'URL is invalid',
  'global.form.errors.password.required': 'Password is required',
  'global.form.errors.firstName.required': 'First name is required',
  'global.form.errors.lastName.required': 'Last name is required',
  'global.form.errors.firstName.invalid': 'Invalid first name',
  'global.form.errors.lastName.invalid': 'Invalid last name',
  'global.form.errors.state.required': 'Choose a state',
  'global.form.errors.workflow.required': 'Choose a workflow',
  'global.form.errors.module.required': 'Choose a module',
  'global.save': 'Save',
  'global.close': 'Close',
  'global.edit': 'Edit',
  'global.view': 'View',
  'global.remove': 'Remove',
  'global.finish': 'Finish',
  'global.password': 'Password',
  'global.or': 'Or',
  'global.next': 'Next',
  'global.confirmAction': 'Confirm Action',
  'global.cancel': 'Cancel',
  'global.upgrade': 'Upgrade',
  'global.subscribe': 'Subscribe',
  'global.form.line1': 'Line 1',
  'global.form.line2': 'Line 2',
  'global.form.country': 'Country',
  'global.form.city': 'City',
  'global.form.zip': 'Zip',
  'global.form.firstName': 'First Name',
  'global.form.lastName': 'Last Name',
  'global.form.phone': 'Phone',
  'global.form.company': 'Company',
  'global.form.email': 'Email',
  'global.openMenu': 'Open menu',
  'global.prediwell': 'Prediwell',
  'global.openMyAccount': 'Open Account Settings',
  'global.internalError': 'Oops, something went wrong. Please try again',

  'module.signin.forgotPassword': 'Forgot password?',
  'module.signin.startFreeTrial': 'Start Free Trial',

  'module.signup.tryPrediwellForFree': 'Try Prediwell For Free',
  'module.signup.immediateAccessNoCC':
    'Immediate access. No credit card Required',
  'module.signup.privacyPolicy':
    'By signin up, you accept our {termsOfService} {privacyPolicy}',
  'module.signup.alreadyRegistered': 'Already have an account? {loginLink}',
  'global.logIn': 'Log in',
  'global.register': 'Register',
  'module.signup.process.loading': 'We are creating your account',
  'module.signup.process.checkEmail':
    'Check your email to validate and to access Prediwell!',
  'module.signup.process.complete':
    'Your account has been successfully created!',

  'module.menu.personal': 'Personal',
  'module.menu.account': 'Account Settings',
  'module.menu.languages': 'Languages',
  'module.menu.logout': 'Log out',
  'module.menu.personal.spaces': 'Your Workspaces',
  'module.languages.french': 'French',
  'module.languages.english': 'English',
  'module.languages.spanish': 'Spanish',

  'module.initialSetup.process.loading': 'We are preparing your workspace ...',
  'module.initialSetup.process.complete':
    'your workspace is ready. Enjoy Prediwell!',

  'module.search.heading': 'Search Prediwell',
  'module.search.input.placeholder': 'Search Prediwell',
  'module.search.description': 'Find your contacts and activities',

  'module.activities.list.emptyList': "You don't have any activities yet.",
  'module.activities.createActivity': 'Create Activity',
  'module.activities.removeActivity': 'Permanently remove activity',
  'module.activities.wizard.workflowConfig.title':
    'configure your activity workflow',
  'module.activities.wizard.contactInformation.title':
    'Enter your contact Information',
  'module.activities.wizard.customFields.title': 'Configure your custom fields',
  'module.activities.wizard.firstName': 'First Name',
  'module.activities.wizard.lastName': 'Last Name',
  'module.activities.wizard.phone': 'Phone',
  'module.activities.wizard.company': 'Company',
  'module.activities.wizard.web': 'Web',
  'module.activities.wizard.position': 'Position',

  'module.activities.tabs.main': 'Details',
  'module.activities.tabs.notes': 'Notes',
  'module.activities.tabs.customFields': 'Custom Fields',
  'module.activities.details.createNote': 'Create Note',
  'module.activities.details.customFields.actions.viewDetails':
    'View custom field details',
  'module.activities.details.customFields.actions.editCustomFieldValue':
    'Edit custom field value',
  'module.activities.details.customFields.editValue': 'Edit Value "{name}"',
  'module.activities.details.notes.emptyList':
    "This activity doesn't have any notes yet.",

  'module.activities.wizard.title': 'Create Activity',
  'module.activities.wizard.similarActivityWarning':
    'There is already a similar activity. Are you sure you want to proceed',

  'module.activities.wizard.process.saving': 'Saving ...',
  'module.activities.wizard.process.complete':
    'Your activity has been successfully created!',

  'module.activities.wizard.form.searchContact': 'Search for contact',
  'module.activities.wizard.form.noContact': 'No contacts found',

  'module.customFields.createCustomField': 'Create Custom Field',
  'module.customFields.emptyList': "You don't have any custom fields yet.",
  'module.customFields.cta.createCustomField': 'Create Custom Field',
  'module.customFields.form.validationRule': 'Validation Rule',
  'module.customFields.form.validationRule.number': 'Number',
  'module.customFields.form.validationRule.URL': 'URL',
  'module.customFields.form.validationRule.date': 'Date',
  'module.customFields.form.validationRule.unspecified': 'Non Specified',

  'module.notImplemented.title':
    'This feature is not implemented on mobile yet.',
  'module.notImplemented.description':
    'Please, access our web application on Desktop for the Prediwell full experience',

  'module.initialSetup.title': 'Helps us to enrich your prediwell experience',
  'module.initialSetup.subtitle': 'We just need extra details about you',
  'module.initialSetup.form.firstName': 'Your First name (Optional)',
  'module.initialSetup.form.lastName': 'Your last name (Optional)',
  'module.initialSetup.form.workingField': 'What is your working field?',
  'module.initialSetup.form.workingField.required': 'Choose a working field',
  'module.initialSetup.form.IT': 'IT',
  'module.initialSetup.form.sales': 'Sales',
  'module.initialSetup.form.recruitment': 'Recruitment',
  'module.initialSetup.form.others': 'Others',
  'module.initialSetup.sales.coldProspect': 'Cold Prospect',
  'module.initialSetup.sales.qualifiedProspect': 'Qualified Prospect',
  'module.initialSetup.sales.lead': 'Lead',
  'module.initialSetup.sales.client': 'Client',
  'module.initialSetup.sales.disqualifiedProspect': 'Disqualified Prospect',
  'module.initialSetup.staff.coldCandidate': 'Cold Candidate',
  'module.initialSetup.staff.qualifiedCandidate': 'Qualified Candidate',
  'module.initialSetup.staff.disqualifiedCandidate': 'Disqualified Candidate',
  'module.initialSetup.staff.probationPeriod': 'Probation Period',
  'module.initialSetup.staff.employmentConfirmed': 'Service Performed',
  'module.initialSetup.it.availableConsultant': 'Available Consultant',
  'module.initialSetup.it.clientInterview': 'Client Interview',
  'module.initialSetup.it.resignedConsultant': 'Resigned Interview',
  'module.initialSetup.it.positionedConsultant': 'Positioned Interview',
  'module.initialSetup.others.lead': 'Lead',
  'module.initialSetup.others.deal': 'Deal',
  'module.initialSetup.others.proposition': 'Proposition',
  'module.initialSetup.actions.cancel': 'Cancel',
  'module.initialSetup.actions.conversation': 'Conversation',
  'module.initialSetup.actions.quoteAccepted': 'Quote Accepted',
  'module.initialSetup.actions.identifiedOpportunity': 'Identified Opportunity',
  'module.initialSetup.actions.quotaAccepted': 'Quota Accepted',
  'module.initialSetup.actions.dismiss': 'Dismiss',
  'module.initialSetup.actions.noInterest': 'No Interest',
  'module.initialSetup.actions.cancelOpportunity': 'Cancelled Opportunity',
  'module.initialSetup.actions.fit': 'Fit',
  'module.initialSetup.actions.unfit': 'Unfit',
  'module.initialSetup.actions.validateByClient': 'Validate By Client',
  'module.initialSetup.actions.confirm': 'Confirm',
  'module.initialSetup.actions.endContract': 'End Contract',
  'module.initialSetup.actions.disqualifiedByClient': 'Disqualified by client',
  'module.initialSetup.actions.position': 'Position',
  'module.initialSetup.actions.consultantQuits': 'Consultant Quits',
  'module.initialSetup.actions.validate': 'Validate',

  'module.account.headings.myAccount': 'My Account',
  'module.account.headings.subscriptionInformation': 'Subscription Information',
  'module.account.headings.billingContact': 'Billing Contact',
  'module.account.headings.paymentMethod': 'Payment Method',
  'module.account.noAddress': "You haven't entered an address",
  'module.account.cancelSubscription': 'Cancel my subscription',
  'module.account.actions.editPaymentMethod': 'Edit Payment Method',
  'module.account.actions.editAddress': 'Edit Payment Address',
  'module.account.actions.editBasicInfo': 'Edit Basic information',
  'module.account.actions.removePaymentMethod': 'remove Payment method',
  'module.account.heading.editAddress': 'Edit Payment Address',
  'module.account.heading.editBasicInformation': 'Edit Information',
  'module.account.heading.editPaymentMethod': 'Edit Payment Method',
  'module.account.form.cardholderName': 'Card holder name',
  'module.account.form.creditCard': 'Credit card',
  'module.account.form.creditCard.error': 'Credit card is required',
  'module.account.form.cardholderName.error': 'Card holder name is required',
  'module.account.form.cancellationReason': 'Cancellation reason',
  'module.account.cancellationResult.success': 'Your subscription was cancelled.',
  'module.account.removePaymentMethod.confirm': 'Are you sure you want to remove your payment method?',

  'module.checkout': 'Checkout',
  'module.checkout.form.plan.required': 'Please choose a plan',
  'module.checkout.perUserMonthly': 'Per user monthly',
  'module.checkout.unlimitedActivities': 'Unlimited activities',
  'module.checkout.unlimitedWorkflows': 'Unlimited workflows',
  'module.checkout.unlimitedSpaces': 'Unlimited spaces',
  'module.checkout.activitiesQuota': 'Up to {quota} activities',
  'module.checkout.spacesQuota': 'Up to {quota} spaces',
  'module.checkout.workflowsQuota': 'Up to {quota} workflows',

  'module.checkout.description.insights': 'Get access to your data insights',
  'module.checkout.description.teamBoard':
    'Improve your users collaboration with interactive teamboards',
  'module.checkout.description.taskManagement':
    'Manage your tasks with the task manager',
  'module.checkout.description.dataExport':
    'Export your activities data into CSV files',
  'module.checkout.description.dataImport':
    'Import your existing business data with our import tool',
  'module.checkout.description.filesStorage':
    'Upload attachment to your activities',
  'module.checkout.description.mailing':
    'Integrate your own mail box into Prediwell',
  'module.checkout.planRequired': 'Please choose a plan',
  'module.checkout.cardDeclined': 'Your card is declined',
  'module.checkout.paymentMethodRequired': 'Please choose a payment method',
  'module.checkout.addressRequired': 'Please enter your address',
  'module.account.paymentProcessing': 'We are processing your payment',
  'module.checkout.basicInfoRequired': 'Please enter your full name',
  'module.checkout.paymentProcessing.finished':
    'Payment processing is now complete.',
  'module.checkout.paymentProcessing.attemptsExhausted':
    'Your payment took longer than expected, please refresh the page',
  'module.checkout.paymentProcessing.started':
    'We are processing your payment, please wait',

  'module.account.expires': 'Expires at',
  'module.account.expiresAt': 'Expires at {date}',
  'module.account.subscriptionExpired': 'Expired at {date}',
  'module.account.subscriptionCancelled': 'Cancelled at {date}',
  'module.account.noPaymentMethod': "You haven't setup a payment method yet",

  'module.userSpaces.heading1': 'Choose Your Workspace',

  'module.userVerification.loading': 'Please wait a few seconds ...',
  'module.userVerification.complete': 'Your account is verified. You\' ll be redirected to login',

  'module.forgotPassword.alreadyRegistered': 'Try another account? {signin}',
  'module.forgotPassword.createAccount': 'Create an account? {signup}',
  'module.forgotPassword.submit': 'Reset Password',
  'module.forgotPassword.title': 'Prediwell',
  'module.forgotPassword.description': 'Please provide us with your email address, and we will help you change your password?',
  'module.forgotPassword.loading': 'Please wait a few seconds ...',
  'module.forgotPassword.complete': 'We have sent you an email. Please check your email address.',

  'module.subscription.restrictedProduct': 'Unavailable Feature',
  'module.subscription.restrictedProduct.message': 'This feature is not available in your plan. Please consider upgrading to enable it',
  'module.subscription.subscriptionRequired': 'You need a subscription to use Prediwell. Try for free now!',
  'module.subscription.subscriptionCanceled': 'Your subscription was cancelled at {cancellationDate}.',
  'module.subscription.subscriptionFailed': 'Your last payment has failed. Please visit your account to resolve the problem',
  'module.subscription.subscriptionExpired': 'Your subscription has expired. Please renew your plan to access your data.',
  'module.subscription.inaccessibleSpace': 'This workspace is inaccessible. Please contact its owner to resolve the issue',
  'module.subscription.incomplete': "You haven't completed your last payment. Please fix your payment method issue",

  'module.fixPayment.chooseAction': 'Choose an action',
  'module.fixPayment.fixIssue': 'Solve Payment Issue',
  'module.fixPayment.completePayment': 'Complete my last payment',
};
