import React from 'react';
import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_ACTIVE_SUBSCRIPTION,
  SET_CURRENT_USER_QUOTA_USAGE,
  SET_CURRENT_USER_SUBSCRIBER,
  SET_CURRENT_USER_SUBSCRIPTION_PLAN,
  SET_PAYMENT_PROCESSING,
  SET_SPACE_SUBSCRIPTION,
  SET_SUBSCRIBER_PAYMENT_METHOD,
  SET_USER_SESSION,
  SET_USER_SESSION_AUTH_DATA_READY,
  SET_USER_SESSION_SPACE_DATA_READY,
  SET_USERS_NUMBER,
} from './reducers';
import UserSession from '../../shared/models/UserSession';
import {
  apiCountSubscriberUsers,
  apiGetAvailableSpaces,
  apiGetCurrentSubscriberAddress,
  apiGetCurrentSubscriberInformation,
  apiGetCurrentUser,
  apiGetCurrentUserActiveSubscription,
  apiGetCurrentUserQuotaUsage,
  apiGetPaymentProcessingStatus,
  apiGetSpaceToken,
  apiGetSubscriberPaymentMethods,
} from './api';
import {
  SET_WORKFLOW_MODULES,
  SET_WORKFLOW_STATES,
  SET_WORKFLOWS,
} from '../Workflows/reducers';
import apiClientRequest from '../../shared/helpers/api-client-request';
import { SET_CUSTOM_FIELDS } from '../CustomFields/reducers';
import { SET_USERS } from '../Users/reducers';
import { apiGetAppProgress } from '../AppTour/api';
import { SET_USER_APP_TOUR } from '../AppTour/reducers';
import { INTERNAL_ERROR } from '../Logs/sagas';
import storage from '../../lib/Storage/storage';
import IntlMessages from '../../shared/intl/IntlMessages';
import NotificationManager from '../../shared/components/Notification/NotificationManager';
import { SET_WORKSPACES } from '../Spaces/reducers';
import sleep from '../../shared/helpers/sleep';

export const SAVE_USER_SESSION = 'SAVE_USER_SESSION';

export const saveUserSessionSaga = async (global, dispatch, accessToken) => {
  await UserSession.persistUserSession(accessToken);
  try {
    const spaces = await apiGetAvailableSpaces();
    const spaceId = spaces[0]._id;
    const spaceToken = await apiGetSpaceToken(spaceId);
    await UserSession.persistSpaceSession(spaceToken);
    const userSession = await UserSession.getUserSession();
    await dispatch[SET_USER_SESSION](userSession);
  } catch (err) {}
};

export const GET_USER_SESSION_SPACE_DATA = 'GET_USER_SESSION_SPACE_DATA';

export const getUserSessionSpaceDataSaga = async (global, dispatch) => {
  try {
    await dispatch[SET_USER_SESSION_SPACE_DATA_READY](false);
    await Promise.all([
      dispatch[SET_WORKFLOWS]({
        loading: true,
      }),
      dispatch[SET_WORKFLOW_MODULES]({
        loading: true,
      }),
      dispatch[SET_WORKFLOW_STATES]({
        loading: true,
      }),
      dispatch[SET_USERS]({
        loading: true,
      }),
    ]);

    const [
      workflows,
      modules,
      states,
      customFields,
      users,
      spaceSubscription,
    ] = await Promise.all([
      apiClientRequest('/workflows/all'),
      apiClientRequest('/workflow-modules/all'),
      apiClientRequest('/workflow-states/all'),
      apiClientRequest('/custom-fields/all'),
      apiClientRequest('/users/all'),
      apiClientRequest('/subscription-info/active-space-subscription'),
    ]);
    await Promise.all([
      dispatch[SET_WORKFLOWS]({
        data: workflows,
        loading: false,
      }),
      dispatch[SET_WORKFLOW_MODULES]({
        data: modules,
        loading: false,
      }),
      dispatch[SET_WORKFLOW_STATES]({
        data: states,
        loading: false,
      }),
      dispatch[SET_CUSTOM_FIELDS]({
        data: customFields,
        loading: false,
      }),
      dispatch[SET_USERS]({
        data: users,
        loading: false,
      }),
      dispatch[SET_SPACE_SUBSCRIPTION](spaceSubscription),
    ]);
    await dispatch[SET_USER_SESSION_SPACE_DATA_READY](true);
  } catch (err) {
    await dispatch[INTERNAL_ERROR](err);
  }
};

export const FETCH_USER_SESSION_AUTH_DATA = 'FETCH_USER_SESSION_AUTH_DATA';

export const fetchUserSessionAuthDataSaga = async (
  global,
  dispatch,
  { initialDataReady = false } = {},
) => {
  try {
    await dispatch[SET_USER_SESSION_AUTH_DATA_READY](initialDataReady);
    await dispatch[SET_USER_APP_TOUR]({
      loading: true,
    });

    const [
      currentUserQuotaUsage,
      currentUserSubscriber,
      currentUserSubscription,
      currentUser,
      userAppTour,
      paymentMethods,
      subscriberAddress,
      usersNumber,
      workspaces,
    ] = await Promise.all([
      apiGetCurrentUserQuotaUsage(),
      apiGetCurrentSubscriberInformation(),
      apiGetCurrentUserActiveSubscription(),
      apiGetCurrentUser(),
      apiGetAppProgress(),
      apiGetSubscriberPaymentMethods(),
      apiGetCurrentSubscriberAddress(),
      apiCountSubscriberUsers(),
      apiGetAvailableSpaces(),
    ]);

    await Promise.all([
      dispatch[SET_CURRENT_USER_SUBSCRIPTION_PLAN]({
        currentUserSubscriptionPlan: currentUserSubscription.subscriptionPlan,
      }),
      dispatch[SET_CURRENT_USER_QUOTA_USAGE]({ currentUserQuotaUsage }),
      dispatch[SET_CURRENT_USER_SUBSCRIBER]({
        currentUserSubscriber: {
          ...currentUserSubscriber,
          address: subscriberAddress,
        },
      }),
      dispatch[SET_CURRENT_USER_ACTIVE_SUBSCRIPTION]({
        currentUserSubscription,
      }),
      dispatch[SET_CURRENT_USER](currentUser),
      dispatch[SET_USER_APP_TOUR]({
        data: userAppTour,
        loading: false,
      }),
      dispatch[SET_SUBSCRIBER_PAYMENT_METHOD]({
        paymentMethod:
          paymentMethods.data.length > 0 ? paymentMethods.data[0] : null,
      }),
      dispatch[SET_USERS_NUMBER](usersNumber),
      dispatch[SET_WORKSPACES](workspaces),
    ]);
    await dispatch[SET_USER_SESSION_AUTH_DATA_READY](true);
  } catch (err) {
    await dispatch[INTERNAL_ERROR](err);
  }
};

export const LOGOUT = 'LOGOUT';

export const logoutSaga = async (global, dispatch) => {
  const anonymousSession = await UserSession.getAnonymousUserSession();
  await dispatch[SET_USER_SESSION](anonymousSession);
  await dispatch[SET_USER_SESSION_SPACE_DATA_READY](false);
  await dispatch[SET_USER_SESSION_AUTH_DATA_READY](false);
  await storage.clear();
};

export const ATTEMPT_TO_UPDATE_SUBSCRIPTION_IF_PAYMENT_IS_PROCESSING =
  'ATTEMPT_TO_UPDATE_SUBSCRIPTION_IF_PAYMENT_IS_PROCESSING';

export const attemptToUpdateSubscriptionIfPaymentIsProcessing = async (
  global,
  dispatch,
) => {
  const paymentProcessing = await apiGetPaymentProcessingStatus();

  if (paymentProcessing) {
    dispatch[ATTEMPT_RELOAD_SESSIONS_AFTER_SUCCESSFUL_CHECKOUT]({
      resume: true,
    });
  }
};

export const ATTEMPT_RELOAD_SESSIONS_AFTER_SUCCESSFUL_CHECKOUT =
  'ATTEMPT_RELOAD_SESSIONS_AFTER_SUCCESSFUL_CHECKOUT';

export const attemptReloadSessionsAfterSuccessfulCheckoutReducer = async (
  global,
  dispatch,
  {
    firstTime = false,
    resume = false,
    lastCheckoutProcessing = null,
    attempts = 5,
  } = {},
) => {
  if (attempts === 0) {
    await dispatch[SET_PAYMENT_PROCESSING](false);
    NotificationManager.success(
      <IntlMessages id="module.checkout.paymentProcessing.attemptsExhausted" />,
    );
    return;
  }

  if (firstTime) {
    NotificationManager.success(
      <IntlMessages id="module.checkout.paymentProcessing.started" />,
    );
  }

  if (global.paymentProcessing || firstTime || resume) {
    await dispatch[UPDATE_CHECKOUT_PROCESSING]();
    await sleep(3500);
    await dispatch[ATTEMPT_RELOAD_SESSIONS_AFTER_SUCCESSFUL_CHECKOUT]({
      lastCheckoutProcessing: global.paymentProcessing,
      attempts: attempts - 1,
    });
    return;
  }

  if (
    !firstTime &&
    !resume &&
    lastCheckoutProcessing &&
    global.paymentProcessing !== lastCheckoutProcessing
  ) {
    await Promise.all([
      dispatch[FETCH_USER_SESSION_AUTH_DATA]({ initialDataReady: true }),
      // dispatch[FETCH_SPACE_SUBSCRIPTION_INFO](),
    ]);
    NotificationManager.success(
      <IntlMessages id="module.checkout.paymentProcessing.finished" />,
    );
  }
};

export const UPDATE_CHECKOUT_PROCESSING = 'UPDATE_CHECKOUT_PROCESSING';

export const updateCheckoutProcessingSaga = async () => {
  const paymentProcessing = await apiGetPaymentProcessingStatus();

  return {
    paymentProcessing,
  };
};

export const UPDATE_SPACE_SESSION = 'UPDATE_SPACE_SESSION';

export const updateSpaceSessionSaga = async (global, dispatch, spaceId) => {
  try {
    const spaceToken = await apiGetSpaceToken(spaceId);
    await UserSession.persistSpaceSession(spaceToken);
    const newUserSession = await UserSession.getUserSession();
    await dispatch[SET_USER_SESSION](newUserSession);
  } catch (err) {
    await dispatch[INTERNAL_ERROR](err);
  }
};
