import axios from 'axios';
import appConfig from '../../config.json';
import storage from '../../lib/Storage/storage';

const getHttpHeaders = async (config) => {
  let headers = {
    ...config.headers,
    'Content-Type': 'application/json',
  };

  const accessToken = await storage.getItem('API_ACCESS_TOKEN');
  const currentSpaceToken = await storage.getItem('CURRENT_SPACE_TOKEN');

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  if (currentSpaceToken && accessToken) {
    headers = {
      ...headers,
      'Set-Cookie': `CURRENT_SPACE_TOKEN=${currentSpaceToken}`,
    };
  }

  return headers;
};

const apiClientRequest = async (path, config = {}, useLegacy = false) => {
  const { method: httpMethod = 'GET' } = config;

  const method = httpMethod.toLowerCase();
  const url = `${appConfig.apiUrl}${path}`;
  const headers = await getHttpHeaders(config);

  try {
    const response = await axios({
      ...config,
      url,
      method,
      headers,
    });

    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export default apiClientRequest;
